import tw from 'twin.macro'
import { useEffect } from 'react'
import { dehydrate, QueryClient } from 'react-query'

import { AHref } from '@/atoms/a-href'
import { Heading } from '@/atoms/headings'

import { getSingle } from '@/hooks/use-cms-data'
import { usePathPrefix } from '@/hooks/use-path-prefix'
import { useWebsite } from '@/hooks/use-website'

export default function PageNotFound() {
  const website = useWebsite()
  const pathPrefix = usePathPrefix()

  useEffect(() => {
    const htmlEl = document.querySelector('html')
    if (htmlEl) {
      htmlEl.dataset.theme = website
    }
  }, [website])

  return (
    <>
      <main tw="bg-[#F0F0F0] min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <section tw="max-w-max mx-auto">
          <div tw="sm:flex mt-20">
            <p tw="text-4xl font-light text-theme-secondary sm:text-5xl">404</p>
            <div tw="sm:ml-6 h-[300px]">
              <div tw="sm:(border-l border-gray-400 pl-6)">
                <Heading as="h1" variant="h2" tw="text-theme-accent">
                  Page not found
                </Heading>
                <p tw="mt-1 text-base text-theme-inverse">
                  Please check the URL in the address bar and try again.
                </p>
              </div>
              <div tw="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <AHref
                  to={`${pathPrefix}/`}
                  theme="accent"
                  variant="contained"
                  tw="font-light text-sm px-4">
                  Homepage
                </AHref>
                <AHref
                  to={`${pathPrefix}/contact-us/`}
                  theme="accent"
                  variant="contained"
                  tw="font-light text-sm px-4">
                  Contact Support
                </AHref>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export async function getStaticProps() {
  const queryClient = new QueryClient()

  // navigation
  await queryClient.prefetchQuery(['navigation', {}], async () =>
    getSingle('navigation')
  )

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      website: 'genting-casinos',
    },
  }
}
